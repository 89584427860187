import Header from '../components/Header';

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, signIn } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  /**
   * If the user gets auth -> user will be redirected to the portal
   *
   * using react-firebase-hooks with useEffect
   * to track the auth state of the user
   */
  useEffect(() => {
    if (loading) {
      // trigger loading screen?
      return;
    }
    if (user) history.replace('/menu');
  }, [user, loading]);

  return (
    <div className="h-screen bg-portalbg">
      {/* header */}
      <Header />

      {/* main */}
      <main className="">
        <div className="flex justify-center mx-auto mt-8 space-x-3 text-center md:mt-16 lg:mt-20">
          <div className="p-4 bg-white border rounded-xl">
            <div className="flex justify-center py-4">
              <h2 className="text-4xl font-extrabold text-primary lg:text-4xl">
                Pagr
              </h2>
              <h2 className="pl-1 text-4xl font-extrabold lg:pl-2 lg:text-4xl ">
                {`Portal`}
              </h2>
              <p className="pl-0.5 text-gray-400 font-thin text-2xl md:text-3xl ">
                ℠
              </p>
            </div>

            <div className="py-4 space-y-4">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="Email"
              />
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="password"
                placeholder="Password"
              />
            </div>

            <div className="flex items-center justify-center space-x-1 md:space-x-2">
              <h2>Don't have an account?</h2>
              <Link to="/register">
                <h2 className="text-lg font-semibold cursor-pointer text-primary">
                  Register
                </h2>
              </Link>
            </div>

            <div className="pb-4">
              {/* <Link to="/menu"> */}
              <button
                onClick={() => signIn(email, password)}
                type="submit"
                className="mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
              >
                Sign In
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
