import PortalHeader from '../components/portal/PortalHeader';
import Sidebar from '../components/portal/Sidebar';
import TrendingItem from '../components/portal/TrendingItem';

import React, { useEffect, useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, logout } from '../firebase';

import { ChevronDownIcon } from '@heroicons/react/solid';

function Trending() {
  const [user, userLoading, userError] = useAuthState(auth);
  const [drinks, setDrinks] = useState([]);
  const [type, setType] = useState('Beer');
  const [sortType, setSortType] = useState('Total Earned');

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    console.log(user?.uid);

    if (sortType === 'Drink') {
      sortDrinks();
    }

    if (sortType === 'Orders') {
      sortOrders();
    }

    if (sortType === 'Price') {
      sortPrice();
    }

    if (sortType === 'Total Earned') {
      sortTotalEarned();
    }
  }, [type]);

  /** gets the drinks sorted by name */
  function sortDrinks() {
    db.collection('Menus')
      .doc(String(user?.uid))
      .collection(`${type}`)
      .orderBy('drinkName', 'asc')
      .onSnapshot((snapshot) =>
        setDrinks(snapshot.docs.map((doc) => doc.data()))
      );
    setSortType('Drink');
  }

  /** gets the drinks sorted by orders */
  function sortOrders() {
    db.collection('Menus')
      .doc(String(user?.uid))
      .collection(`${type}`)
      .orderBy('drinksSold', 'desc')
      .onSnapshot((snapshot) =>
        setDrinks(snapshot.docs.map((doc) => doc.data()))
      );
    setSortType('Orders');
  }

  /** gets the drinks sorted by price
   *
   * @todo need to switch price from string to number
   */
  function sortPrice() {
    db.collection('Menus')
      .doc(String(user?.uid))
      .collection(`${type}`)
      .orderBy('drinkPrice', 'asc')
      .onSnapshot((snapshot) =>
        setDrinks(snapshot.docs.map((doc) => doc.data()))
      );
    setSortType('Price');
  }

  /** gets the drinks sorted by total earned */
  function sortTotalEarned() {
    db.collection('Menus')
      .doc(String(user?.uid))
      .collection(`${type}`)
      .orderBy('drinksSold', 'desc')
      .onSnapshot((snapshot) =>
        setDrinks(snapshot.docs.map((doc) => doc.data()))
      );
    setSortType('Total Earned');
  }

  function changeType(type: string) {
    const sortingType = sortType;

    console.log(sortingType);

    setType(type);
    setSortType(sortingType);
  }

  return (
    <div className="h-screen bg-portalbg ">
      <PortalHeader title="Trending" message="Coming soon" />

      <main className="flex ">
        <Sidebar />

        <div className="justify-center w-full mt-4 md:text-lg lg:text-xl">
          <div className="flex items-center justify-center mt-20">
            <h2 className="text-xl font-bold md:text-3xl">
              See your trending drinks once Pagr is live
            </h2>
          </div>
        </div>

        {/* <div className="flex-grow h-screen overflow-hidden xl:mr-40">
          <div className="max-w-md mx-auto md:max-w-lg lg:max-w-4xl">
            <div className="flex flex-grow py-4 lg:py-6">
              <div className="flex items-center justify-center w-full space-x-8 md:text-lg lg:text-xl">
                <h2
                  onClick={() => changeType('Beer')}
                  className={` border-b-4 border-transparent text-black ${
                    type === 'Beer' && 'border-black'
                  }  cursor-pointer`}
                >
                  Beer
                </h2>

                <h2
                  onClick={() => setType('Cocktails')}
                  className={` border-b-4 text-black border-transparent cursor-pointer ${
                    type === 'Cocktails' && 'border-black'
                  }`}
                >
                  Cocktails
                </h2>
                <h2
                  onClick={() => setType('Seltzer')}
                  className={`border-b-4 border-transparent cursor-pointer ${
                    type === `Seltzer` && 'border-black'
                  }`}
                >
                  Seltzer
                </h2>
                <h2
                  onClick={() => setType('Shots')}
                  className={`border-b-4 border-transparent cursor-pointer ${
                    type === 'Shots' && 'border-black'
                  }`}
                >
                  Shots
                </h2>
              </div>
            </div>

            <div className="bg-white border-t border-b sm:rounded-lg sm:border">
              <div className="grid grid-cols-4 px-2 py-3 font-bold border-b md:px-4 text-md lg:text-lg">
                <div className="flex items-center">
                  <h2
                    onClick={() => sortDrinks()}
                    className={`cursor-pointer text-left opacity-50 ${
                      sortType === 'Drink' && 'opacity-100'
                    }`}
                  >
                    Drink
                  </h2>
                </div>
                <div className="flex items-center justify-center">
                  <h2
                    onClick={() => sortOrders()}
                    className={`cursor-pointer text-center opacity-50 ${
                      sortType === 'Orders' && 'opacity-100'
                    }`}
                  >
                    Orders
                  </h2>
                </div>
                <div className="flex items-center justify-center">
                  <h2
                    onClick={() => sortPrice()}
                    className={`cursor-pointer text-center opacity-50 ${
                      sortType === 'Price' && 'opacity-100'
                    }`}
                  >
                    Price
                  </h2>
                </div>
                <div className="flex items-center justify-end">
                  <h2
                    onClick={() => sortTotalEarned()}
                    className={`cursor-pointer text-center opacity-50 ${
                      sortType === 'Total Earned' && 'opacity-100'
                    }`}
                  >
                    Total Earned
                  </h2>
                </div>
              </div>

              {drinks?.map((item) => (
                <TrendingItem
                  type={''}
                  key={item.id}
                  drinkName={item.drinkName}
                  price={item.drinkPrice}
                  drinksSold={item.drinksSold}
                  earned={item.drinksSold * item.drinkPrice}
                />
              ))}
            </div>
          </div>
        </div> */}
      </main>
    </div>
    // <div className="h-screen bg-portalbg ">
    //   <PortalHeader title="Trending" message="Coming soon" />

    //   <main className="flex ">
    //     {/* sidebar */}
    //     <Sidebar />

    //     {/* middle */}

    //     <div className="flex justify-center w-full mt-20">
    //       <div className="bg-white border-t border-b sm:rounded-lg sm:border">
    //         {/* top */}

    //         <div className="grid grid-cols-3 px-2 py-3 font-bold border-b md:px-4 text-md lg:text-lg">
    //           <h2 className="text-left">Drink</h2>
    //           <h2 className="text-center">Sold</h2>
    //           <h2 className="text-right">Earned</h2>
    //         </div>

    //         {drinks?.map((item) => (
    //           <TrendingItem
    //             type={''}
    //             key={item.id}
    //             drinkName={item.drinkName}
    //             price={item.drinkPrice}
    //             drinksSold={item.drinksSold}
    //             earned={item.drinksSold * item.drinkPrice}
    //           />
    //         ))}
    //       </div>
    //     </div>
    //   </main>
    // </div>
  );
}

export default Trending;
