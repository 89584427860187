import React, { Component } from 'react';
import Header from '../Header';
import BusinessInfoStep from './BusinessInfoStep';
import PersonalInfoStep from './PersonalInfoStep';
import MenuSetupStep from './MenuSetupStep';
import { useHistory } from 'react-router-dom';

export class Form extends Component {
  state = {
    step: 1,
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    businessName: '',
    ein: '',
    ownerName: '',
    streetName: '',
    city: '',
    state: '',
    zipCode: '',
    /** bar url? */
  };

  beerItems = [];

  /** handles moving to the next step */
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  /** handles moving to the previous step */
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  inputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  render() {
    const { step } = this.state;

    const {
      name,
      email,
      phone,
      password,
      confirmPassword,
      businessName,
      ein,
      ownerName,
      streetName,
      city,
      state,
      zipCode,
    } = this.state;

    const values = {
      name,
      email,
      phone,
      password,
      confirmPassword,
      businessName,
      ein,
      ownerName,
      streetName,
      city,
      state,
      zipCode,
    };

    switch (step) {
      case 1:
        return (
          <PersonalInfoStep
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            values={values}
          />
        );

      case 2:
        return (
          <BusinessInfoStep
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 3:
        return (
          <MenuSetupStep
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
    }
  }
}

export default Form;
