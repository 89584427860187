function OnboardingCard({ title, body, image }: any) {
  return (
    <div className="grid items-center justify-center grid-cols-1 mx-8 text-center">
      <div className="md:px-20 ">
        <h2 className="text-xl font-extrabold sm:text-2xl lg:text-3xl xl:text-4xl">
          {title}
        </h2>
        <p className="mt-2 text-sm text-gray-400 md:px-8 sm:mt-3 md:mt-4 lg:mt-6 sm:text-md lg:text-base xl:text-lg lg:px-12">
          {body}
        </p>
      </div>

      <div className="mx-auto mt-4 sm:mt-3 md:mt-6 lg:mt-8">
        <img
          className="object-contain w-full h-48 md:h-56 lg:h-60"
          src={image}
          alt={title}
        />
      </div>
    </div>
  );
}

export default OnboardingCard;
