import PortalHeader from '../components/portal/PortalHeader';
import Sidebar from '../components/portal/Sidebar';

import { db, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import React, { useState, useEffect } from 'react';

function Banking() {
  const [user, userLoading, userError] = useAuthState(auth);

  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    db.collection('owners')
      .doc(`${user?.uid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();

          setAccountNumber(data.accountNumber);
          setRoutingNumber(data.routingNumber);
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);

  const handleAccountNumber = (e) => {
    e.preventDefault();
    let value = e.target.value;

    if (value.length > 12) {
      return;
    }

    setAccountNumber(value);
  };

  const handleRoutingNumber = (e) => {
    e.preventDefault();

    let value = e.target.value;

    if (value.length > 9) {
      return;
    }

    setRoutingNumber(value);
  };

  const pushBankingInfo = () => {
    try {
      let isAccountNum = /^\d+$/.test(accountNumber);
      let isRoutingNum = /^\d+$/.test(routingNumber);

      if (accountNumber === '') {
        alert('Account number cannot be empty');
        return;
      }

      if (!isAccountNum) {
        alert('Your account number must be numbers');
        setAccountNumber('');
        return;
      }

      if (routingNumber === '') {
        alert('Routing number cannot be empty');
        return;
      }

      if (!isRoutingNum) {
        alert('Your routing number must be numbers');
        setRoutingNumber('');
        return;
      }

      db.collection('owners')
        .doc(`${user?.uid}`)
        .update({
          accountNumber: accountNumber,
          routingNumber: routingNumber,
        })
        .then(() => {
          console.log('Document successfully updated!');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
    } catch (err) {
      alert(`There was an error saving your banking information: ${err}`);
    }
  };

  return (
    <div className="h-screen bg-portalbg ">
      <PortalHeader
        title="Banking"
        message="Edit your banking information here"
      />

      <main className="flex">
        {/* sidebar */}
        <Sidebar />

        {/* banking section */}

        <div className="flex-grow h-screen py-4 overflow-hidden sm:mt-4 md:mt-8 lg:mt-12 lg:mr-40">
          <div className="w-full max-w-md p-2 px-4 mx-auto bg-white border-b md:w-2/3 sm:rounded-lg sm:border sm:max-w-md lg:max-w-4xl">
            <div className="space-y-3 md:mt-2">
              <div className="">
                <h2 className="font-bold">Account Number</h2>
                <input
                  value={accountNumber}
                  onChange={(e) => handleAccountNumber(e)}
                  className="w-full p-2 border rounded-lg focus:outline-primary"
                  type="password"
                  placeholder="account #"
                />
              </div>

              <div className="">
                <h2 className="font-bold">Routing Number</h2>
                <input
                  value={routingNumber}
                  onChange={(e) => handleRoutingNumber(e)}
                  className="w-full p-2 border rounded-lg focus:outline-primary"
                  type="password"
                  placeholder="routing #"
                />
              </div>

              <div className="flex justify-center pb-2 md:justify-end">
                <button
                  onClick={() => pushBankingInfo()}
                  className="mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Banking;
