import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/solid';

import Header from '../../components/Header';

/** material tailwind */
import Modal from '@material-tailwind/react/Modal';
import ModalHeader from '@material-tailwind/react/ModalHeader';
import ModalBody from '@material-tailwind/react/ModalBody';
import ModalFooter from '@material-tailwind/react/ModalFooter';

import { Link, useHistory } from 'react-router-dom';

import { db, auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

/**
 * todo add portal header when signed in
 * ```typescript
 * import PortalHeader from '../components/portal/PortalHeader';
 * ```
 */

interface IMenuItem {
  drinkName: string;
  drinkPrice: string;
  selected: boolean;
}

function SetupSelzer() {
  const history = useHistory();

  const [showModal, setShowModal] = React.useState(false);
  const [modalText, setModaltext] = React.useState('');
  /** add item modal */
  const [showAddItem, setShowAddItem] = React.useState(false);

  const [staticSeltzerItems, setStaticSeltzerItems] = useState<IMenuItem[]>([]);

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (!user) return;
  }, [user, loading]);

  useEffect(() => {
    setStaticSeltzerItems([
      {
        drinkName: 'Truly - Wild Berry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Blueberry & Acai',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Raspberry Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Black Cherry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Grapefruit',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Citrus',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Lemon',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Pineapple',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Passion Fruit',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Truly - Watermelon & Kiwi',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Black Cherry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Watermelon',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Natural Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Ruby Grapefruit',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Raspberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Lemon',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Tangerine',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Strawberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Blackberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw - Pineapple',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw Iced Tea - Lemon',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw Iced Tea - Raspberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw Iced Tea - Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'White Claw Iced Tea - Peach',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Bud Light Seltzer - Strawberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Bud Light Seltzer - Strawberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Bud Light Seltzer - Black Cherry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Bud Light Seltzer - Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Blueberry Pomegranate',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Strawberry Kiwi',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Black Cherry Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Pineapple Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Pineapple Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Raspberry Tangerine',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Papaya Passionfruit',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Blackberry Lemon',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Raspberry Lemonade',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Raspberry Lemonade',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Peach Lemonade',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Vizzy - Watermelon Lemonade',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Strawberry Lemonade',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Strawberry Kiwi',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Black Cherry Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Black Cherry Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Black Cherry Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Blue Raspberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Green Apple',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Natural Light Seltzer - Lemon',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Corona Hard Seltzer - Blackberry Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Corona Hard Seltzer - Tropical Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Corona Hard Seltzer - Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Corona Hard Seltzer - Mango',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Corona Hard Seltzer - Pineapple',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Corona Hard Seltzer - Strawberry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'Corona Hard Seltzer - Passionfruit',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'High Noon - Pineapple',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'High Noon - Grapefruit',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'High Noon - Lime',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'High Noon - Peach',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'High Noon - Watermelon',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'High Noon - Black Cherry',
        drinkPrice: '0',
        selected: false,
      },
      {
        drinkName: 'High Noon - Mango',
        drinkPrice: '0',
        selected: false,
      },
    ]);
  }, []);

  const [seltzerItems, setSeltzerItems] = useState<IMenuItem[]>([]);

  const [customDrinkName, setCustomDrinkName] = useState('');
  const [customDrinkPrice, setCustomDrinkPrice] = useState('');

  /** adds items user selected to firestore
   *
   * navigates user to next page
   *
   * if no items are selected -> user gets navigated
   */
  const pushItems = () => {
    if (seltzerItems.length > 0) {
      seltzerItems.map((item) => {
        db.collection('Menus')
          .doc(`${user?.uid}`)
          .collection('Seltzer')
          .add({
            drinkName: item.drinkName,
            drinkPrice: item.drinkPrice,
            inStock: item.selected,
            drinksSold: 0,
          })
          .then((docRef) => {
            console.log('Document written with ID: ', docRef.id);
          })
          .catch((error) => {
            console.error('Error adding document: ', error);
          });
      });
    } else {
      console.log('seltzerItems is empty -> can nav to next page');
      /** navigate
       * todo: add router at bottom of function?
       */
    }

    history.replace('/setupShots');
  };

  const addCustomItem = (e) => {
    e.preventDefault();

    if (customDrinkName !== '' && customDrinkPrice !== '') {
      let selected = true;

      setSeltzerItems([
        ...seltzerItems,
        {
          drinkName: customDrinkName,
          drinkPrice: customDrinkPrice,
          selected: true,
        },
      ]);

      setStaticSeltzerItems([
        {
          drinkName: customDrinkName,
          drinkPrice: customDrinkPrice,
          selected: true,
        },
        ...staticSeltzerItems,
      ]);
    }

    /** closing modal & setting custom state to empty values */
    setShowAddItem(false);
    setCustomDrinkName('');
    setCustomDrinkPrice('');
  };

  const addToItems = (e, item: IMenuItem) => {
    let isChecked = e.target.checked;

    console.log(`IS CHECKED: ${isChecked}`);

    console.log(item);

    let price = parseInt(item.drinkPrice);

    if (isChecked) {
      /** checks if price is 0 */
      if (price === 0) {
        setModaltext(`You can't leave the price blank!`);
        setShowModal(true);

        e.target.checked = false;
        return;
      }

      // if (price < 0) {
      //   setModaltext(`The price must be above 0!`);
      //   setShowModal(true);

      //   e.target.checked = false;
      //   return;
      // }
      // e.target.checked = false;

      let newItem = item;
      newItem.selected = true;
      setSeltzerItems([...seltzerItems, newItem]);
      item.selected = true;
    } else {
      // e.target.checked = false;

      setSeltzerItems(
        seltzerItems.filter(
          (filteredItem) => filteredItem.drinkName !== item.drinkName
        )
      );

      item.selected = false;
    }
  };

  /**
   * Handles the input for each item
   *
   * if the input value is not a number ->
   * the value is set to null
   *
   * if the input value is below 0 ->
   * the value is set to null
   *
   * @param e input event
   * @param item `IMenuItem`
   */
  const handlePriceInput = (e, item: IMenuItem) => {
    // let isnum = /^\d+$/.test(e.target.value);
    let isnum = /^[0-9]+(\.)?[0-9]*$/.test(e.target.value);

    if (!isnum) {
      e.target.value = '';
      return;
    }

    if (e.target.value < 0) {
      e.target.value = '';
      return;
    }

    console.log(e.target.value);
    console.log(`ITEM PRICE: ${item.drinkPrice}`);
    item.drinkPrice = e.target.value;
  };

  return (
    <div className="h-full bg-portalbg ">
      <Header />

      <main className="">
        {/* modal */}
        <Modal size="sm" active={showModal} toggler={() => setShowModal(false)}>
          <ModalHeader toggler={() => setShowModal(false)}>Whoops!</ModalHeader>
          <ModalBody>
            <p className="text-base font-normal leading-relaxed text-gray-600">
              {modalText}
            </p>
          </ModalBody>
          {/* <ModalFooter> */}
          <div className="flex items-center justify-center">
            <button
              className="px-6 justify-end font-semibold py-1.5 md:text-md text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
              onClick={(e) => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </Modal>

        {/* custom item modal */}
        <Modal
          size="sm"
          active={showAddItem}
          toggler={() => setShowAddItem(false)}
        >
          <ModalHeader toggler={() => setShowAddItem(false)}>
            Add item
          </ModalHeader>
          <ModalBody>
            <p className="text-base font-normal leading-relaxed text-gray-600"></p>

            <div className="space-y-4">
              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="Drink Name"
                onChange={(e) => setCustomDrinkName(e.target.value)}
              />

              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="number"
                placeholder="Drink Price"
                onChange={(e) => setCustomDrinkPrice(e.target.value)}
              />
            </div>
          </ModalBody>
          {/* <ModalFooter> */}
          <div className="flex items-center justify-between">
            <button className="font-medium text-red-500">Cancel</button>
            <button
              className="px-6 justify-end font-semibold py-1.5 md:text-md text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
              // onClick={(e) => setShowAddItem(false)}
              onClick={(e) => addCustomItem(e)}
            >
              Add
            </button>
          </div>
        </Modal>

        <div className="flex justify-center mx-auto mt-8 space-x-3 text-center md:mt-16 lg:mt-20">
          <div className="p-4 mx-auto bg-white border md:px-10 rounded-xl">
            {/* title */}

            <h2 className="text-xl font-bold">Next let's add seltzer items</h2>

            <div className="py-2 text-sm text-gray-700">
              <p>
                You can select from the list of seltzer items or add your own
              </p>
              <p>
                Note: For each of the items you select, please enter a price for
                each item
              </p>
            </div>

            {/* button for custom item modal popup */}

            <div className="flex items-center justify-between py-4">
              <div
                onClick={(e) => setShowAddItem(true)}
                className="flex justify-end p-2 font-semibold text-white transition duration-150 transform rounded-full cursor-pointer md:text-md bg-gradient-to-r from-primary to-secondary active:scale-90"
              >
                <PlusIcon className="w-5 text-white" />
              </div>

              <button
                onClick={() => pushItems()}
                className="px-6 justify-end font-semibold py-1.5 md:text-md text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
              >
                Next
              </button>
            </div>

            {/* title labels */}

            {/* <h2 className="font-bold">{`Seltzer items Count: ${seltzerItems.length}`}</h2>
            {seltzerItems.map((item) => (
              <h2>
                {`DRINK: ${item.drinkName} PRICE: ${item.drinkPrice} SELECTED: ${item.selected}`}
              </h2>
            ))} */}

            <div className="grid grid-cols-3 px-2 py-3 font-bold border-b md:px-4 text-md lg:text-lg">
              <h2 className="text-left">Drink</h2>
              <h2 className="text-center">Price</h2>
              <h2 className="text-right">Select</h2>
            </div>

            {/* list of seltzer items w/ checkbox */}

            {staticSeltzerItems.map((item) => (
              <div className="grid grid-cols-3 text-gray-700 items-center px-2 py-2.5 text-sm md:px-4">
                <div className="flex justify-start">
                  <h2 className="text-left">{item.drinkName}</h2>
                </div>

                <div className="flex items-center justify-center ">
                  <h2 className="text-lg">$</h2>
                  <input
                    className="w-10 text-lg outline-none"
                    placeholder="0"
                    onChange={(e) => handlePriceInput(e, item)}
                  />
                </div>

                <div className="flex justify-end">
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={(e) => addToItems(e, item)}
                    className="w-5 h-5 text-yellow-300 text-teal-600 rounded-sm outline-none form-checkbox"
                  ></input>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default SetupSelzer;
