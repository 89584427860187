import { Link, useHistory } from 'react-router-dom';

function Landing() {
  return (
    <section className="grid grid-cols-1 mx-6 mt-8 md:mx-20 sm:mt-10 md:mt-20 lg:mt-28 sm:grid-cols-2">
      {/* main */}
      {/* left */}

      <div className="items-center place-self-center">
        <div className="flex">
          <h1 className="text-4xl font-extrabold text-primary sm:text-5xl lg:text-6xl font-poppins">
            Pagr
          </h1>
          <p className="pl-0.5 text-gray-400 font-thin text-2xl md:text-3xl md:py-1 lg:py-2">
            ℠
          </p>
        </div>

        <h3 className="text-2xl font-bold xl:font-extrabold md:pt-2 lg:pt-3 w-100 lg:text-4xl sm:w-60 md:w-96">
          Elevating the guest experience with a modern approach.
        </h3>

        {/* download icons */}
        <div className="flex mt-3 space-x-2 md:mt-4">
          {/*
           * Link to app store
           * TODO: replace with app id
           */}
          <Link
            to={{
              pathname:
                'https://apps.apple.com/us/app/pagr-mobile-ordering/id1536949332',
            }}
            target="_blank"
          >
            <img
              className="object-contain w-32 cursor-pointer md:w-40"
              src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Flanding%2Fapp-store.svg?alt=media&token=a4314c53-64ff-416b-a347-cd78149e29fa"
              alt="app store download button"
            />
          </Link>

          {/*
           * Link to google play store
           */}

          <Link
            to={{ pathname: 'https://play.google.com/store' }}
            target="_blank"
          >
            <img
              className="hidden object-contain cursor-pointer w-36 md:w-44"
              src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Flanding%2Fplay-store.svg?alt=media&token=d9bd88ba-23fa-4f95-a829-5257a5072429"
              alt="play store download button"
            />
          </Link>
        </div>
      </div>

      <div className="mx-auto mt-2 md:mt-0 sm:w-1/2 md:w-2/3">
        <img
          className="object-contain transition duration-150 ease-out transform cursor-pointer lg:hover:rotate-6 lg:hover:scale-125"
          src="./img/mockups.png"
          alt="bar detail page & bars near me page"
        />
      </div>

      {/* right */}
    </section>
  );
}

export default Landing;
