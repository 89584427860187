import MenuBar from './MenuBar';
import MenuItem from '../portal/MenuItem';
import { useHistory } from 'react-router';
import { useCollection } from 'react-firebase-hooks/firestore';

import { Link } from 'react-router-dom';

import React, { useEffect, useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, logout } from '../../firebase';

/** modal */
import Modal from '@material-tailwind/react/Modal';
import ModalHeader from '@material-tailwind/react/ModalHeader';
import ModalBody from '@material-tailwind/react/ModalBody';
import ModalFooter from '@material-tailwind/react/ModalFooter';

function MenuSection() {
  /**
   * NOTES:
   * - archive?
   * - sort by drink type?
   *
   */

  let history = useHistory();

  const [user, userLoading, userError] = useAuthState(auth);

  const [showAddItem, setShowAddItem] = React.useState(false);

  const [customDrinkName, setCustomDrinkName] = useState('');
  const [customDrinkPrice, setCustomDrinkPrice] = useState('');

  const [drinks, setDrinks] = useState([]);
  const [type, setType] = useState('Beer');

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    console.log(user?.uid);

    db.collection('Menus')
      .doc(String(user?.uid))
      .collection(`${type}`)
      /**
       * TODO: Add archive so users can see archived they deleted?
       */
      // .where('archived', '==', false)
      .onSnapshot((snapshot) =>
        setDrinks(snapshot.docs.map((doc) => doc.data()))
      );
  }, [type]);

  /**
   * hook with 3 states:
   * @realtimeDrinks
   * @loading
   * @error
   */

  const addCustomItem = (e) => {
    e.preventDefault();

    if (customDrinkName !== '' && customDrinkPrice !== '') {
      let selected = true;

      db.collection('Menus')
        .doc(`${user?.uid}`)
        .collection(type)
        .add({
          drinkName: customDrinkName,
          drinkPrice: customDrinkPrice,
          inStock: true,
          drinksSold: 0,
        })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    }

    /** closing modal & setting custom state to empty values */
    setShowAddItem(false);
    setCustomDrinkName('');
    setCustomDrinkPrice('');
  };

  const handlePriceInput = (e) => {
    // let isnum = /^\d+$/.test(e.target.value);
    let isnum = /^[0-9]+(\.)?[0-9]*$/.test(e.target.value);

    if (!isnum) {
      e.target.value = '';
      return;
    }

    if (e.target.value < 0) {
      e.target.value = '';
      return;
    }

    console.log(e.target.value);

    setCustomDrinkPrice(e.target.value);
  };

  return (
    <div className="flex-grow h-screen overflow-hidden xl:mr-40">
      <div className="max-w-md mx-auto md:max-w-lg lg:max-w-4xl">
        <Modal
          size="sm"
          active={showAddItem}
          toggler={() => setShowAddItem(false)}
        >
          <ModalHeader toggler={() => setShowAddItem(false)}>
            Add item
          </ModalHeader>
          <ModalBody>
            <p className="text-base font-normal leading-relaxed text-gray-600"></p>

            <div className="space-y-4">
              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="Drink Name"
                value={customDrinkName}
                onChange={(e) => setCustomDrinkName(e.target.value)}
              />

              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="number"
                value={customDrinkPrice}
                placeholder="Drink Price"
                // onChange={(e) => setCustomDrinkPrice(e.target.value)}
                onChange={(e) => handlePriceInput(e)}
              />
            </div>
          </ModalBody>
          {/* <ModalFooter> */}
          <div className="flex items-center justify-between">
            <button className="font-medium text-red-500">Cancel</button>
            <button
              className="px-6 justify-end font-semibold py-1.5 md:text-md text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
              // onClick={(e) => setShowAddItem(false)}
              onClick={(e) => addCustomItem(e)}
            >
              Add
            </button>
          </div>
        </Modal>

        {/* <MenuBar /> */}
        <div className="flex flex-grow py-4 lg:py-6">
          <div className="flex items-center justify-center w-full space-x-8 md:text-lg lg:text-xl">
            <h2
              onClick={() => setType('Beer')}
              className={` border-b-4 border-transparent text-black ${
                type === 'Beer' && 'border-black'
              }  cursor-pointer`}
            >
              Beer
            </h2>

            <h2
              onClick={() => setType('Cocktails')}
              className={` border-b-4 text-black border-transparent cursor-pointer ${
                type === 'Cocktails' && 'border-black'
              }`}
            >
              Cocktails
            </h2>
            <h2
              onClick={() => setType('Seltzer')}
              className={`border-b-4 border-transparent cursor-pointer ${
                type === `Seltzer` && 'border-black'
              }`}
            >
              Seltzer
            </h2>
            <h2
              onClick={() => setType('Shots')}
              className={`border-b-4 border-transparent cursor-pointer ${
                type === 'Shots' && 'border-black'
              }`}
            >
              Shots
            </h2>
          </div>

          <div>
            <button
              onClick={(e) => setShowAddItem(true)}
              className="hidden md:inline-flex px-8 justify-end font-semibold py-1.5 md:text-md text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
            >
              Add
            </button>
          </div>
        </div>

        <div className="bg-white border-t border-b sm:rounded-lg sm:border">
          {/* top */}

          <div className="grid grid-cols-3 px-2 py-3 font-bold border-b md:px-4 text-md lg:text-lg">
            <h2 className="text-left">Drink</h2>
            <h2 className="text-center">Price</h2>
            <h2 className="text-right">In Stock</h2>
          </div>

          {/* bottom */}

          {drinks?.map((item) => (
            <MenuItem
              type={type}
              key={item.id}
              drinkName={item.drinkName}
              price={item.drinkPrice}
              inStock={item.inStock}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MenuSection;
