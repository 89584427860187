import PortalHeader from '../components/portal/PortalHeader';
import Sidebar from '../components/portal/Sidebar';
import BarInfo from '../components/portal/BarInfo';

import { db, auth, storage } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState, useEffect, useRef } from 'react';

function Bar() {
  const [user, userLoading, userError] = useAuthState(auth);

  const [barName, setbarName] = useState('');

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    db.collection('owners')
      .doc(`${user?.uid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();

          setbarName(data.businessName);
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);

  return (
    <div className="h-screen bg-portalbg ">
      <PortalHeader
        title={barName}
        message="View and edit your bar image here"
      />

      <main className="flex">
        {/* sidebar */}
        <Sidebar />
        <BarInfo />
      </main>
    </div>
  );
}

export default Bar;
