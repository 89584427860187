import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';

export class PersonalInfoStep extends Component<any, any> {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="flex justify-center mx-auto mt-8 space-x-3 text-center md:mt-16 lg:mt-20">
        <div className="p-4 mx-auto bg-white border rounded-xl">
          <div className="items-center py-2">
            <div className="flex justify-center ">
              <h2 className="text-3xl font-extrabold lg:text-4xl">Register</h2>
            </div>
            <h2>Personal Info</h2>
          </div>

          <div className="py-4 space-y-4">
            {/* name */}
            <input
              className="w-full p-2 border rounded-lg focus:outline-primary"
              type="text"
              placeholder="Your Name"
              name="name"
              onChange={inputChange('name')}
              value={values.name}
            />

            {/* email */}
            <input
              className="w-full p-2 border rounded-lg focus:outline-primary"
              type="text"
              placeholder="Your Email"
              name="email"
              onChange={inputChange('email')}
              value={values.email}
            />

            {/* password */}
            <input
              className="w-full p-2 border rounded-lg focus:outline-primary"
              type="password"
              placeholder="Your Password"
              name="email"
              onChange={inputChange('password')}
              value={values.password}
            />

            {/* confirm password */}
            <input
              className="w-full p-2 border rounded-lg focus:outline-primary"
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={inputChange('confirmPassword')}
              value={values.confirmPassword}
            />
          </div>

          {/* button */}

          <div>
            <div className="flex justify-end">
              <button
                className=" mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
                onClick={this.continue}
              >
                Next
              </button>
            </div>

            <div className="flex items-center justify-center pt-3 space-x-1 md:space-x-2">
              <h2>Already have an account?</h2>
              <Link to="/login">
                <h2 className="text-lg font-semibold cursor-pointer text-primary">
                  Back to Login
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalInfoStep;
