import PortalHeader from '../components/portal/PortalHeader';
import Sidebar from '../components/portal/Sidebar';
import { PhoneIcon, MailIcon } from '@heroicons/react/solid';

function help() {
  return (
    <div className="h-screen bg-portalbg ">
      <PortalHeader
        title="Help"
        message="Contact support and someone will be in touch"
      />

      <main className="flex ">
        {/* sidebar */}
        <Sidebar />

        {/* coming soon */}
        <div className="flex justify-center w-full h-screen py-4 overflow-hidden sm:mt-4 md:mt-8 lg:mt-12 lg:mr-40">
          <div className="space-y-2">
            <h2 className="text-2xl font-bold lg:text-3xl">Contact Us</h2>

            <h2 className="text-gray-500">
              You can contact us for any issues or concerns
            </h2>

            <div className="py-4">
              <div className="flex space-x-3">
                <PhoneIcon className="h-7 text-primary " />
                <h2 className="font-semibold lg:text-lg">(216) 544-4263</h2>
              </div>

              <div className="flex space-x-3">
                <MailIcon className="h-7 text-primary" />
                <h2 className="font-semibold lg:text-lg">support@pagr.app</h2>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default help;
