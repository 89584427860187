import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';

export class MenuSetupStep extends Component<any, any> {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="flex justify-center mx-auto mt-8 space-x-3 text-center md:mt-16 lg:mt-20">
        <div className="p-10 mx-auto bg-white border rounded-xl">
          <div className="items-center py-2">
            <div className="flex justify-center ">
              <h2 className="text-2xl font-extrabold lg:text-4xl">Welcome!</h2>
            </div>
            <h2>Would you like to setup your menu now?</h2>
          </div>

          {/* button */}

          <div className="mb-4 space-y-3">
            <div className="flex justify-center">
              <Link to="/setupbeer">
                <button
                  className=" mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
                  // onClick={this.continue}
                >
                  Let's do it!
                </button>
              </Link>
            </div>

            <div className="flex items-center justify-center pt-3 space-x-1 text-black md:space-x-2">
              <Link to="/menu">
                <h2 className="text-lg font-semibold cursor-pointer ">
                  Skip for now
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuSetupStep;
