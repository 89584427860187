import { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  /** react hook router */

  return (
    <header className="sticky top-0 z-50 flex justify-between p-5 bg-white border-b border-gray-200 items shadow:sm">
      {/* Image container */}
      <div className="relative flex items-center h-10 my-auto cursor-pointer">
        <Link to="/">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Fmain%2Flogo.svg?alt=media&token=70b44e51-fecc-4851-a4d2-c74e8f50b140"
            width="60px"
            height="60px"
            alt="pagr logo"
          ></img>
        </Link>
      </div>

      {/* right */}

      <div className="flex items-center justify-end space-x-4">
        {/* <h2 className="font-bold cursor-pointer text-md">About</h2>
        <h2 className="font-bold cursor-pointer">Contact</h2> */}
        <Link to="/login">
          <h2 className="font-bold cursor-pointer text-primary">Pagr Portal</h2>
        </Link>
      </div>
    </header>
  );
}

export default Header;
