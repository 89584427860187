import PortalHeader from '../components/portal/PortalHeader';
import Sidebar from '../components/portal/Sidebar';
import OrderItem from '../components/portal/OrderItem';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import moment from 'moment';

import { Fragment, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, logout } from '../firebase';

import { Menu, Transition } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/solid';

function Dashboard() {
  const [user, userLoading, userError] = useAuthState(auth);
  const [drinks, setDrinks] = useState([]);
  const [type, setType] = useState('orders');

  const [viewType, setViewType] = useState('1Year');

  const [startText, setStartText] = useState('');
  const [endText, setEndText] = useState('');

  const formatXAxis = (tickItem) => {
    return tickItem.toLocaleDateString();
  };

  /** chart */
  var tooltip;
  const CustomTooltip = ({ test, active, payload, label }) => {
    console.log(`${test}`);

    var epoch = label;

    var testDate = new Date(epoch);

    var formattedDate = moment(testDate).format('MMM Do');

    console.log(`DATE ${testDate}`);

    if (active && payload && payload.length) {
      if (type == 'orders') {
        console.log(`PAYLOAD: ${label.value}`);
        return (
          <div className="p-4 bg-white border-2 rounded-md custom-tooltip">
            <p className="label">{`Orders: ${payload[0].value}`}</p>
            <p className="intro">{`${formattedDate}`}</p>
          </div>
        );
      } else {
        return (
          <div className="p-4 bg-white border-2 rounded-md custom-tooltip">
            <p className="label">{`$${payload[0].value}`}</p>
            <p className="intro">{`${formattedDate}`}</p>
          </div>
        );
      }
    }

    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    const dateTip = moment(payload.value).format('ll').slice(0, 6);
    return (
      <g transform={`translate(${x},${y})`}>
        {/* <text
          x={23}
          y={0}
          dy={14}
          fontSize="0.90em"
          // fontFamily="bold"
          textAnchor="end"
          fill="#363636"
        >
          {dateTip}
        </text> */}
      </g>
    );
  };
  /** end of chart */

  /** gets the epoch date
   * @param date
   * @returns parsed version of date
   */
  function epoch(date) {
    return Date.parse(date);
  }

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    console.log(user?.uid);

    if (viewType === '1Day') {
      get7Days();
    }

    if (viewType === '1Month') {
      get1Month();
    }

    if (viewType === '1Year') {
      get1Year();
    }
  }, [type]);

  function get1Day() {
    console.log('1 days tapped');

    var orders = [];
    var currentDrinks = [];

    var today = new Date();
    console.log(`TODAY: ${today}`);
    const oneDayAgo = moment(today).subtract('days', 1).toDate();

    console.log(`7 DAYS AGO: ${oneDayAgo}`);

    // setStartText(`${moment(oneDayAgo).format('ll')}`);
    // setEndText(`${moment(today).format('ll')}`);
    setStartText('Yesterday');
    setEndText('Today');

    db.collection('orders')
      .where('bar', '==', user.uid)
      .where('status', '==', 'Completed')
      .where('created', '>', oneDayAgo)
      .orderBy('created', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, ' => ', doc.data());

          var drink = doc.data();
          // drink.date = drink.created.toDate().toDateString();
          var date = drink.created.toDate();
          drink.date = epoch(date);
          drink.revenue = drink.total;
          drink.orders = drink.details.orders.length;
          orders.push(drink);
        });
        setDrinks(orders);
        setViewType('1Month');
      });
  }

  function get7Days() {
    console.log('7 days tapped');

    var orders = [];
    var currentDrinks = [];

    var today = new Date();
    console.log(`TODAY: ${today}`);

    const sevenDaysAgo = moment(today).subtract('days', 7).toDate();

    setStartText(`${moment(sevenDaysAgo).format('dddd')}`);
    // setEndText(`${moment(today).format('dddd')}`);
    setEndText('Today');

    db.collection('orders')
      .where('bar', '==', user.uid)
      .where('status', '==', 'Completed')
      .where('created', '>', sevenDaysAgo)
      .orderBy('created', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, ' => ', doc.data());

          var drink = doc.data();
          // drink.date = drink.created.toDate().toDateString();
          var date = drink.created.toDate();
          drink.date = epoch(date);
          drink.revenue = drink.total;
          drink.orders = drink.details.orders.length;
          orders.push(drink);
        });
        setDrinks(orders);
        setViewType('1Month');
      });
  }

  function get1Month() {
    console.log('1 month tapped');

    var orders = [];
    var currentDrinks = [];

    var today = new Date();
    console.log(`TODAY: ${today}`);
    const oneMonthAgo = moment(today).subtract('months', 1).toDate();

    setStartText(`${moment(oneMonthAgo).format('ll')}`);
    setEndText(`${moment(today).format('ll')}`);

    console.log(`ONE MONTH AGO: ${oneMonthAgo}`);

    db.collection('orders')
      .where('bar', '==', user.uid)
      .where('status', '==', 'Completed')
      .where('created', '>', oneMonthAgo)
      .orderBy('created', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, ' => ', doc.data());

          var drink = doc.data();
          // drink.date = drink.created.toDate().toDateString();
          var date = drink.created.toDate();
          drink.date = epoch(date);
          drink.revenue = drink.total;
          drink.orders = drink.details.orders.length;
          orders.push(drink);
        });
        setDrinks(orders);
        setViewType('1Month');
      });
  }

  function get1Year() {
    console.log('1 year tapped');

    var orders = [];
    var currentDrinks = [];

    var today = new Date();
    console.log(`TODAY: ${today}`);
    const oneYearAgo = moment(today).subtract('years', 1).toDate();

    console.log(`ONE YEAR AGO: ${oneYearAgo}`);

    setStartText(`${moment(oneYearAgo).format('ll')}`);
    setEndText(`${moment(today).format('ll')}`);

    db.collection('orders')
      .where('bar', '==', user.uid)
      .where('status', '==', 'Completed')
      .where('created', '>', oneYearAgo)
      .orderBy('created', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, ' => ', doc.data());

          var drink = doc.data();
          // drink.date = drink.created.toDate().toDateString();
          var date = drink.created.toDate();
          drink.date = epoch(date);
          // drink.date = date;
          drink.revenue = drink.total;
          drink.orders = drink.details.orders.length;
          orders.push(drink);
        });
        setDrinks(orders);
        setViewType('1Year');
      });
  }

  return (
    <div className="h-screen bg-portalbg ">
      <PortalHeader title="Dashboard" message="Coming soon" />
      <main className="flex">
        {/* sidebar */}
        <Sidebar />

        <div className="justify-center w-full mt-4 md:text-lg lg:text-xl">
          <div className="flex items-center justify-center mt-20">
            <h2 className="text-xl font-bold md:text-3xl">
              Review all analytics once Pagr is live
            </h2>
          </div>
        </div>

        {/* <div className="justify-center w-full mt-4 md:text-lg lg:text-xl">
          <div className="flex flex-grow py-4 lg:py-6">
            <div className="flex items-center justify-center w-full space-x-8 md:text-lg lg:text-xl">
              <h2
                onClick={() => setType('revenue')}
                className={` border-b-4 border-transparent text-black ${
                  type === 'revenue' && 'border-black'
                }  cursor-pointer`}
              >
                Revenue
              </h2>

              <h2
                onClick={() => setType('orders')}
                className={` border-b-4 text-black border-transparent cursor-pointer ${
                  type === 'orders' && 'border-black'
                }`}
              >
                Orders
              </h2>
            </div>
          </div>

          <ResponsiveContainer width="100%" height="50%">
            <LineChart
              width={500}
              height={300}
              data={drinks}
              margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                content={
                  <CustomTooltip
                    test="test"
                    label="hi"
                    active="true"
                    payload={`${type}`}
                  />
                }
              />
              <XAxis
                type="number"
                dataKey="date"
                // minTickGap={1}
                tickFormatter={(date) => moment(date).format('M / D')}
                allowDuplicatedCategory={false}
                domain={['auto', 'auto']}
                // tickFormatter={formatXAxis}
                tick={CustomizedAxisTick}
              />
              <YAxis />

              <Line
                type="monotone"
                dataKey={type}
                stroke="#000000"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>

          <div className="flex justify-between mx-20">
            <h3>{startText}</h3>
            <h3>{endText}</h3>
          </div>

          <div className="justify-end hidden pr-10 md:flex ">
            <div className="fixed w-56 mt-10 text-right top-16">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md bg-primary hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    View By
                    <ChevronDownIcon
                      className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-index: 50">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={get1Day}
                          className={`${
                            active
                              ? 'bg-violet-500 text-primary'
                              : 'text-gray-900'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          ) : (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          )}
                          1 Day
                        </button>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={get7Days}
                          className={`${
                            active
                              ? 'bg-violet-500 text-primary'
                              : 'text-gray-900'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          ) : (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          )}
                          7 Days
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={get1Month}
                          className={`${
                            active
                              ? 'bg-violet-500 text-primary'
                              : 'text-gray-900'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          ) : (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          )}
                          1 Month
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={get1Year}
                          className={`${
                            active
                              ? 'bg-violet-500 text-primary'
                              : 'text-gray-900'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          ) : (
                            <CheckCircleIcon
                              className="w-5 h-5 mr-2"
                              aria-hidden="true"
                            />
                          )}
                          1 Year
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
}

export default Dashboard;
