import { PencilIcon } from '@heroicons/react/outline';

import { db, auth, storage, firebase } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState, useEffect, useRef } from 'react';

import Geocode from 'react-geocode';

function BarInfo() {
  const [user, userLoading, userError] = useAuthState(auth);

  const [barName, setBarName] = useState('');

  const [streetName, setStreetName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  // const inputRef = useRef(null);
  const filePickerRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    db.collection('bars')
      .doc(`${user?.uid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data());

          let data = doc.data();
          setBarName(data.name);
          setStreetName(data.streetName);
          setCity(data.city);
          setState(data.state);
          setZipCode(data.zipCode);
        } else {
          console.log('No such document');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    db.collection('bars')
      .doc(`${user?.uid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data());

          let data = doc.data();
          setImage(data.image);
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);

  const saveBarInfo = async (e) => {
    e.preventDefault();

    const address = `${streetName} ${city}, ${state} ${zipCode}`;

    /** Validate fields */

    if (barName === '') {
      alert('Bar Name cannot be empty!');
      return;
    }

    if (streetName === '') {
      alert('Street Name cannot be empty!');
      return;
    }

    if (city === '') {
      alert('City cannot be empty!');
      return;
    }

    if (state === '') {
      alert('State cannot be empty!');
      return;
    }

    if (zipCode === '') {
      alert('Zip code cannot be empty!');
      return;
    }

    console.log(`LAT ${latitude}`);

    db.collection('owners').doc(`${user?.uid}`).update({
      businessName: barName,
      address: address,
    });

    db.collection('bars').doc(`${user?.uid}`).update({
      uid: user.uid,
      name: barName,
      streetName: streetName,
      city: city,
      state: state,
      zipCode: zipCode,
      address: address,
      // lat: latitude,
      // lon: longitude,
    });

    /** Upload bar image
     * TODO: don't upload each time?
     *
     */

    uploadBarImage(e);

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage('en');
    Geocode.setLocationType('ROOFTOP');
    Geocode.enableDebug();

    // Get latitude & longitude from address.
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
        // latitude = lat;
        // longitude = lng;

        db.collection('bars')
          .doc(`${user?.uid}`)
          .update({
            // lat: lat,
            // lon: lng,
            location: new firebase.firestore.GeoPoint(lat, lng),
          });

        // setLatitude(lat);
        // setLongitude(lng);
      },
      (error) => {
        console.error(error);
        alert('Invalid address!');
        return;
      }
    );
  };

  const uploadBarImage = async (e) => {
    // e.preventDefault();

    // db.collection('bars').doc(`${user?.uid}`).update

    if (!imageChanged) {
      console.log(`Image wasn't changed`);
      return;
    }

    console.log('test');

    if (image) {
      const uploadTask = storage
        .ref(`bars/${user?.uid}`)
        .putString(image, 'data_url');

      // removeImage();

      uploadTask.on(
        'state_change',
        null,
        (error) => console.error(error),
        () => {
          storage
            .ref(`bars/${user?.uid}`)
            .getDownloadURL()
            .then((url) => {
              db.collection('bars').doc(`${user?.uid}`).set(
                {
                  image: url,
                },
                { merge: true }
              );
            });
        }
      );
    }

    // inputRef.current.value = '';
  };

  const addImageToPost = (e) => {
    const reader = new FileReader();

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }

    reader.onload = (readerEvent) => {
      setImage(readerEvent.target.result);
      setImageChanged(true);
    };
  };

  const removeImage = () => {
    setImage(null);
  };
  return (
    <div className="flex-grow h-screen overflow-hidden sm:mt-4 md:mt-8 lg:mt-12 lg:mr-40">
      <div className="w-full max-w-md p-2 px-4 mx-auto bg-white border-b md:w-2/3 sm:rounded-lg sm:border sm:max-w-md lg:max-w-4xl ">
        {/* Image section*/}

        <div className="justify-center lg:flex">
          <div className="md:mt-2">
            <div className="flex py-2 space-x-3">
              <h2 className="font-bold">Change Image</h2>
              {/* <PencilIcon className="h-5" /> */}
            </div>

            <div className="bg-gray-300 rounded-lg h-52 w-72">
              <img
                className="object-cover rounded-lg h-52 w-72"
                src={image}
                alt=""
              />
              {/* image here */}
            </div>

            <input
              className="mt-4"
              ref={filePickerRef}
              type="file"
              onChange={addImageToPost}
            />
          </div>

          <div className="md:mt-2">
            {/* change name */}
            <div className="py-2 ">
              <h2 className="font-bold">Change Bar Name</h2>
              <input
                className="w-full p-2 mt-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="Bar Name"
                value={barName}
                onChange={(e) => setBarName(e.target.value)}
              />
            </div>

            {/* change bar */}
            <div className="py-2 ">
              <h2 className="font-bold">Change Address</h2>

              <div className="mt-2 space-y-3">
                <div className="flex space-x-3">
                  {/* street name */}
                  <input
                    className="w-full p-2 border rounded-lg focus:outline-primary"
                    type="text"
                    placeholder="Street name"
                    value={streetName}
                    onChange={(e) => setStreetName(e.target.value)}
                  />

                  {/* city */}
                  <input
                    className="w-full p-2 border rounded-lg focus:outline-primary"
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="flex space-x-3">
                  {/* state */}
                  <input
                    className="w-full p-2 border rounded-lg focus:outline-primary"
                    type="text"
                    placeholder="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />

                  {/* zip code */}
                  <input
                    className="w-full p-2 border rounded-lg focus:outline-primary"
                    type="number"
                    placeholder="Zip code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-2 md:justify-end">
          <button
            onClick={saveBarInfo}
            className="mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default BarInfo;
