import Form from '../components/registration/RegistrationForm';
import Header from '../components/Header';
function Register() {
  return (
    <div className="h-screen bg-portalbg">
      <Header />

      <main>
        <Form />
      </main>
    </div>
  );
}

export default Register;
