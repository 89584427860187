function Footer() {
  return (
    <div className="px-6 pt-4 pb-4 border-t bg-portalbg lg:mb-6 sm:px-8 md:px-12 lg:px-20 xl:px-40 ">
      {/* top */}

      <div className="flex items-center pt-2 ">
        <h2 className="text-2xl font-extrabold text-primary md:text-2xl lg:text-3xl">
          Pagr
        </h2>

        <p className="pl-0.5 text-gray-400 pt-1 lg:pt-2 font-thin text-2xl md:text-3xl ">
          ℠
        </p>
      </div>

      <p className="py-2 text-xs text-gray-400">
        © Copyright 2020-2021 Pagr.app, All Rights Reserved
      </p>

      {/* bottom */}

      <div className="grid items-center grid-cols-3 ">
        <p className="col-span-2 text-xs text-gray-600">
          Combining the convenience of customers to order ahead and interact
          with one another as the first official platform for the nightlife
          industry.
        </p>

        <div className="flex justify-end space-x-3 lg:space-x-4">
          <img
            className="w-5 cursor-pointer"
            src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Flanding%2Finstagram.svg?alt=media&token=94b26fb0-b7c1-4d0e-ae8d-5951d79e9aba"
            alt=""
          />

          <img
            className="w-5 cursor-pointer"
            src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Flanding%2Ftwitter.svg?alt=media&token=b79734c0-655e-4ae7-a80b-1a0146983a1a"
            alt=""
          />

          <img
            className="w-5 cursor-pointer"
            src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Flanding%2Ffacebook.svg?alt=media&token=9f2e8b0f-2808-483e-8d5e-e84131b0b18a"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
