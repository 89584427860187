import firebase from 'firebase';

import Geocode from 'react-geocode';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage('en');

/** checks if firebase hasn't been initialized yet */
const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

const auth = app.auth();
const db = app.firestore();
const storage = firebase.storage();

/**  set location_type filter . Its optional.
 * google geocoder returns more that one address for given lat/lng.
 * In some case we need one address as response for which google itself provides a location_type filter.
 *
 * So we can easily parse the result for fetching address components
 * ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
 * And according to the below google docs in description, ROOFTOP param returns the most accurate result.
 **/
Geocode.setLocationType('ROOFTOP');

/** Enable or disable logs. Its optional. */
Geocode.enableDebug();

/** Sign in */

/** signs user in with email
 *
 * try catch -> calls `signInWithEmailPassword` from firebase auth
 *
 * @param email: user's email
 * @param password: user's password
 *
 * @throws error if firebase returns error
 * - error is logged and an alert is shown with the error message
 */
const signIn = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (error) {
    console.log(error);
    console.error(error.message);
    alert(error.message);
  }
};

/** Logout */

/** simple function that calls `auth.signOut`
 *
 * session handled by firebase
 */
const logout = () => {
  auth.signOut();
};

/** Register */

/**
 * Registers user with email & password
 *
 * try catch -> calls `createUserWithEmailAndPassword` from firebase auth
 *
 * TODO: Add owner to bar and owners?
 *
 * data is sent to firestore upon successful sign up
 * @param name: user's name
 * @param email: user's email
 * @param password: user's password
 *
 * @throws error if firebase returns error
 * - error is logged & an alert is shown with the error message
 */

const register = async (
  email,
  password,
  name,
  businessName,
  owner,
  streetName,
  city,
  state,
  zipCode
) => {
  try {
    const address = `${streetName} ${city}, ${state} ${zipCode}`;

    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;

    await db.collection('owners').doc(`${user?.uid}`).set({
      uid: user.uid,
      name: name,
      email: email,
      owner: owner,
      businessName: businessName,
      address: address,
    });

    await db.collection('bars').doc(`${user?.uid}`).set({
      uid: user.uid,
      name: businessName,
      streetName: streetName,
      city: city,
      state: state,
      zipCode: zipCode,
      address: address,
      // location: new firebase.firestore.GeoPoint(latitude, longitude),
    });

    // Get latitude & longitude from address.
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);

        db.collection('bars')
          .doc(`${user?.uid}`)
          .update({
            // lat: lat,
            // lon: lng,
            location: new firebase.firestore.GeoPoint(lat, lng),
          });
      },
      (error) => {
        console.error(error);
        alert('Invalid address!');
        return false;
      }
    );

    return true;
  } catch (error) {
    console.log(error);
    console.error(error.message);
    alert(error.message);
    return false;
  }
};

/** Password reset */

/**
 * Sends a link to the user's email to reset password
 *
 * try catch -> calls firebase function `sendPasswordResetEmail`
 *
 * @param email: user's email
 *
 * @throws error if one is returned from firebase
 */
const resetPassword = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export { auth, db, storage, signIn, register, resetPassword, logout, firebase };
