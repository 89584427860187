import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth, db, logout } from '../../firebase';
import { useHistory } from 'react-router';
import { useAuthState } from 'react-firebase-hooks/auth';

function PortalHeader({ title, message }) {
  const [user, loading, error] = useAuthState(auth);
  const [barName, setBarName] = useState('');
  const [image, setImage] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace('/');
  }, [user, loading]);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    db.collection('owners')
      .doc(`${user?.uid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data());
          var data = doc.data();

          var businessName = data.businessName;
          setBarName(businessName);

          console.log(`BUSINESS NAME: ${businessName}`);
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);

  return (
    <header className="sticky top-0 z-50 flex justify-between pt-5 pb-5 pl-5 bg-white border-b border-gray-200 items shadow:sm">
      {/* Image container */}
      <div className="relative flex items-center h-10 cursor-pointer">
        <Link to="/">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Fmain%2Flogo.svg?alt=media&token=70b44e51-fecc-4851-a4d2-c74e8f50b140"
            width="60px"
            height="60px"
            alt="pagr logo"
          ></img>
        </Link>
        <div className="justify-center pl-2 md:pl-4">
          <h2 className="text-2xl font-bold text-gray-900 lg:text-3xl">
            {title}
          </h2>
          <p className="hidden text-xs text-gray-400 md:flex">{message}</p>
        </div>
      </div>

      {/* right */}

      <div className="flex items-center justify-end pl-6 pr-2 md:border-l md:pr-4 ">
        <Link to="/bar">
          {/* <img
            className="w-10 h-10 rounded-full cursor-pointer lg:w-12 lg:h-12"
            src={image}
            alt=""
          /> */}
        </Link>

        <div className="inline-flex pl-2 md:pl-4">
          <div className="">
            <h2 className="text-sm font-semibold">Hi there,</h2>
            {/* <Link to="/login"> */}
            <h2
              onClick={logout}
              className="text-sm text-gray-500 cursor-pointer"
            >
              sign out
            </h2>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default PortalHeader;
