import { Link } from 'react-router-dom';

function LandingPortalTop() {
  return (
    <section className="flex flex-wrap items-center justify-center mx-8 mt-12 mb-20 md:mt-16 lg:mt-20 md:mx-20 place-self-center">
      <div className="">
        <div className="flex">
          <h2 className="text-3xl font-extrabold text-primary md:text-3xl lg:text-4xl xl:text-5xl">
            Pagr
          </h2>
          <h2 className="pl-1 text-3xl font-extrabold lg:pl-2 md:text-3xl lg:text-4xl xl:text-5xl">
            {`Portal`}
          </h2>
          <p className="pl-0.5 text-gray-400 font-thin text-2xl md:text-3xl ">
            ℠
          </p>
        </div>

        <p className="pt-2 text-xs text-gray-400 lg:text-sm md:pt-3 lg:pt-4 md:w-3/5 ">
          Manage your bar and register now!
        </p>

        <Link to="/register">
          <button className="mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform">
            Register
          </button>
        </Link>
      </div>

      <div className="">
        <img
          className="object-contain w-full transition duration-200 ease-out transform cursor-pointer sm:h-96 lg:hover:rotate-2 lg:hover:scale-125"
          src="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Flanding%2Fpagr-portal-mockup.png?alt=media&token=d800fcfb-0685-419d-a55a-f17080f46eeb"
          alt=""
        />
      </div>
    </section>
  );
}

export default LandingPortalTop;
