import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/solid';

import Modal from '@material-tailwind/react/Modal';
import ModalHeader from '@material-tailwind/react/ModalHeader';
import ModalBody from '@material-tailwind/react/ModalBody';
import ModalFooter from '@material-tailwind/react/ModalFooter';

import { db, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Link, useHistory, useLocation } from 'react-router-dom';

import beerResponse from '../data/beer.json';

interface IMenuItem {
  drinkName: string;
  drinkPrice: string;
  selected: boolean;
}

function Edit() {
  const location = useLocation();
  const type = location.state;

  const [showModal, setShowModal] = React.useState(false);
  const [modalText, setModalText] = React.useState('');

  const [showAddItem, setShowAddItem] = React.useState(false);

  const [staticItems, setStaticItems] = useState<IMenuItem[]>([]);

  const [user, loading, error] = useAuthState(auth);

  const movies = beerResponse;

  useEffect(() => {
    if (loading) return;
    if (!user) return;
  }, [user, loading]);

  useEffect(() => {
    if (type == 'Beer') {
      setStaticItems(beerResponse);
      console.log(movies);
    }
  }, []);

  const [items, setItems] = useState<IMenuItem[]>([]);

  const [customDrinkName, setCustomDrinkName] = useState('');
  const [customDrinkPrice, setCustomDrinkPrice] = useState('');

  return (
    <div>
      <h2>edit</h2>
      <h2>{type}</h2>
    </div>
  );
}

export default Edit;
