import { Switch, Route } from 'react-router-dom';
import Home from './pages/home';
import Register from './pages/register';
import Login from './pages/login';
/** portal */
import Menu from './pages/menu';
import Edit from './pages/edit';
import Dashboard from './pages/dashboard';
import Trending from './pages/trending';
import Banking from './pages/banking';
import Help from './pages/help';
import Bar from './pages/bar';
/** setup */
import SetupBeer from './pages/setup/setupBeer';
import SetupCocktails from './pages/setup/setupCocktails';
import SetupSeltzer from './pages/setup/setupSeltzer';
import SetupShots from './pages/setup/setupShots';

import { auth } from './firebase';
import React, { useEffect, useState } from 'react';
import logging from './config/logging';

function App() {
  require('dotenv').config();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        logging.info('user detected');
      } else {
        logging.info('no user detected');
      }

      setLoading(false);
    });
  }, []);

  return (
    <div>
      {/* home */}
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <Route path="/register">
          <Register />
        </Route>

        {/* setup routes */}
        <Route path="/setupbeer">
          <SetupBeer />
        </Route>

        <Route path="/setupCocktails">
          <SetupCocktails />
        </Route>

        <Route path="/setupSeltzer">
          <SetupSeltzer />
        </Route>

        <Route path="/setupShots">
          <SetupShots />
        </Route>

        {/* portal routes */}
        <Route path="/menu">
          <Menu />
        </Route>

        <Route path="/edit">
          <Edit />
        </Route>

        <Route path="/dashboard">
          <Dashboard />
        </Route>

        <Route path="/trending">
          <Trending />
        </Route>

        <Route path="/banking">
          <Banking />
        </Route>

        <Route path="/help">
          <Help />
        </Route>

        <Route path="/bar">
          <Bar />
        </Route>

        {/* default route */}
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
