import SidebarRow from './SidebarRow';
import {
  BookOpenIcon,
  ViewGridIcon,
  QuestionMarkCircleIcon,
  LibraryIcon,
  TrendingUpIcon,
  UserCircleIcon,
} from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, logout } from '../../firebase';
import React, { useState, useEffect } from 'react';

function Sidebar() {
  const [user, userLoading, userError] = useAuthState(auth);
  const [barName, setBarName] = useState('');

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    db.collection('owners')
      .doc(`${user?.uid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data());
          var data = doc.data();

          var businessName = data.businessName;
          setBarName(businessName);

          console.log(`BUSINESS NAME: ${businessName}`);
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);
  return (
    <div className="p-2 xl:pr-6 max-w-[300px] md:space-y-2 xl:min-w-[200px] h-screen bg-white border-r border-gray-200">
      <div>
        <Link to="/menu">
          <SidebarRow Icon={BookOpenIcon} title="Menu" />
        </Link>
      </div>
      <div>
        <Link to="/dashboard">
          <SidebarRow Icon={ViewGridIcon} title="Dashboard" />
        </Link>
      </div>
      <div>
        <Link to="/trending">
          <SidebarRow Icon={TrendingUpIcon} title="Trending" />
        </Link>
      </div>
      <div>
        <Link to="/banking">
          <SidebarRow Icon={LibraryIcon} title="Banking" />
        </Link>
      </div>
      <div>
        <Link to="/help">
          <SidebarRow Icon={QuestionMarkCircleIcon} title="Help" />
        </Link>
      </div>

      <div>
        <Link to="/bar">
          <SidebarRow Icon={UserCircleIcon} title={barName} />
        </Link>
      </div>

      {/* <div className="hidden mt-4 border-t md:flex">
        <Link to="/bar">
          <div className="items-center self-center justify-center hidden w-full p-4 mt-2 space-x-2 text-center cursor-pointer justify-items-center md:flex sm:rounded-xl hover:bg-gray-100">
            <h2 className="font-semibold text-gray-700">{barName}</h2>
          </div>
        </Link>
      </div> */}
    </div>
  );
}

export default Sidebar;
