import OnboardingCard from '../components/OnboardingCard';

function Onboarding() {
  return (
    <div className="space-y-16 md:space-y-24 lg:space-y-32">
      <OnboardingCard
        title="Skip the line"
        body="No more waiting in line to place your order, just order from your phone and pickup at the designated location when your drink is ready"
        image="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Fonboarding%2Fskip-line.svg?alt=media&token=7ad62011-a354-4a15-8105-3487b7ac6a36"
      />
      <OnboardingCard
        title="Go Cashless"
        body="Whether through your credit card or Venmo, pay straight from your phone and never worry about losing your card again"
        image="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Fonboarding%2Fcashless.svg?alt=media&token=577c012b-77b8-46ea-b819-91382a22d0d6"
      />

      <OnboardingCard
        title="Split Tabs"
        body="Split your tab with your friends and never forget to close out again"
        image="https://firebasestorage.googleapis.com/v0/b/pagr-customer.appspot.com/o/web%2Fonboarding%2Fsplit-tabs.svg?alt=media&token=275d378c-7dec-4875-8cf6-5ff0ac1f202c"
      />
    </div>
  );
}

export default Onboarding;
