interface SidebarRowProps {
  title: string;
  active?: boolean;
  Icon: any;
}

function SidebarRow({ title, Icon, active }: SidebarRowProps) {
  return (
    <div
      // className={`flex items-center px-4 py-4 md:py-3 space-x-2 rounded-full cursor-pointer sm:rounded-xl hover:bg-gray-100 ${
      //   active && 'bg-gray-100'
      // }`}
      className={`flex items-center px-4 py-4 md:py-3 space-x-2 rounded-full cursor-pointer sm:rounded-xl hover:bg-gray-100 `}
    >
      <Icon
        // className={` text-gray-300 w-8 h-8 lg:w-10 lg:h-10 ${
        //   active && 'text-yellow-300 '
        // }`}
        className={` text-gray-300 w-8 h-8 lg:w-10 lg:h-10 `}
      />
      <p
        className={`hidden text-gray-800 sm:inline-flex ${
          active && `font-bold`
        }  `}
      >
        {title}
      </p>
    </div>
  );
}

export default SidebarRow;
