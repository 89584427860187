import PortalHeader from '../components/portal/PortalHeader';
import Sidebar from '../components/portal/Sidebar';
import MenuSection from '../components/portal/MenuSection';

function Menu() {
  return (
    /** header */
    <div className="h-screen bg-portalbg ">
      <PortalHeader title="Menu" message="View and edit your menu here" />

      <main className="flex ">
        {/* sidebar */}

        <Sidebar />

        {/* Menu */}
        <MenuSection />
      </main>
    </div>
  );
}

export default Menu;
// p-2 mt-5 max-w-[600px] xl:min-w-[300px]
