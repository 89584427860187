import Header from '../components/Header';
import Landing from '../components/Landing';
import Onboarding from '../components/Onboarding';
import LandingPortalTop from '../components/LandingPortalTop';
import Footer from '../components/Footer';

function Home() {
  return (
    <div>
      <Header />
      <main>
        {/* Landing */}
        <Landing />

        {/* <MiddleLanding /> */}
        <section className="flex items-center w-full my-auto text-center sm:mt-20 md:mt-32 h-96">
          <div className="w-2/3 mx-auto space-y-1 sm:space-y-3 ">
            <h2 className="text-4xl font-extrabold md:text-5xl text-primary">
              Easier Ordering
            </h2>
            <h2 className="text-xl font-bold md:font-bold sm:text-2xl">
              No more waiting in line
            </h2>
          </div>
        </section>

        <Onboarding />

        <LandingPortalTop />
      </main>

      <Footer />
    </div>
  );
}

export default Home;
