import { TrashIcon } from '@heroicons/react/solid';
import React, { useState, useEffect } from 'react';

import { db, auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function MenuItem({ drinkName, price, inStock, type }) {
  // const setStockStatus = (e) => {};
  const [status, setStatus] = useState(inStock);

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (!user) return;
  }, [user, loading]);

  const changeStatus = (e, inStock, drinkName) => {
    console.log(`IN STOCK?: ${inStock}`);
    console.log(type);

    if (inStock) {
      /** item is in stock */
    } else {
      /** item is not in stock */
    }

    try {
      db.collection('Menus')
        .doc(`${user?.uid}`)
        .collection(type)
        .where('drinkName', '==', drinkName)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, ' => ', doc.data());
            db.collection('Menus')
              .doc(`${user?.uid}`)
              .collection(type)
              .doc(doc.id)
              .update({
                inStock: !inStock,
              });

            e.target.checked = !inStock;
          });
        });
    } catch (error) {
      alert(`There was an error: ${error}`);
    }
  };

  /** Removes a document from firestore
   *
   * takes in event and drinkName
   * Finds the corresponding document with the drinkName
   *
   * and deletes it from firestore
   */
  const removeItem = (e, drinkName) => {
    // db.collection().
    console.log(type);

    try {
      db.collection('Menus')
        .doc(`${user?.uid}`)
        .collection(type)
        .where('drinkName', '==', drinkName)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, ' => ', doc.data());

            db.collection('Menus')
              .doc(`${user?.uid}`)
              .collection(type)
              .doc(doc.id)
              .delete()
              .then(() => {
                console.log('Document successfully deleted!');
              })
              .catch((error) => {
                console.error('Error removing document: ', error);
              });
          });
        });
    } catch (error) {
      alert(`There was an error: ${error}`);
    }
  };

  return (
    <div className="grid grid-cols-3 text-gray-700 items-center px-2 py-2.5 text-sm md:px-4">
      <h2 className="text-left">{drinkName}</h2>
      <h2 className="text-center">${price}</h2>
      <div className="flex items-center justify-end space-x-6">
        {/* <h2 className="text-right ">{inStock ? 'yes' : 'no'}</h2> */}

        <input
          type="checkbox"
          checked={inStock}
          onChange={(e) => changeStatus(e, inStock, drinkName)}
          className="w-5 h-5 text-yellow-300 text-teal-600 rounded-sm outline-none form-checkbox"
        ></input>

        <TrashIcon
          onClick={(e) => removeItem(e, drinkName)}
          className="w-6 h-6 text-red-300"
        />
      </div>
    </div>
  );
}

export default MenuItem;
