import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { auth, register } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import { withRouter } from 'react-router-dom';

export class BusinessInfoStep extends Component<any, any> {
  continue = (e) => {
    e.preventDefault();
    // this.props.nextStep();

    console.log(this.props.values.name);
    let name = this.props.values.name.trim();
    let email = this.props.values.email.trim();
    let password = this.props.values.password.trim();
    let confirmPassword = this.props.values.confirmPassword.trim();
    let businessName = this.props.values.businessName.trim();
    let ein = this.props.values.ein.trim();
    let ownerName = this.props.values.ownerName.trim();
    let streetName = this.props.values.streetName.trim();
    let city = this.props.values.city.trim();
    let state = this.props.values.state.trim();
    let zipCode = this.props.values.zipCode.trim();

    if (name === '') {
      alert(`Name cannot be empty!`);
      return;
    }

    if (email === '') {
      alert(`Email cannot be empty!`);
      return;
    }

    /** password */
    if (password === '') {
      alert(`Password cannot be empty!`);
      return;
    }

    if (confirmPassword === '') {
      alert(`Confirm Password cannot be empty!`);
      return;
    }

    if (password !== confirmPassword) {
      alert(`Passwords do not match!`);
      return;
    }

    if (businessName === '') {
      alert(`Your business name cannot be empty!`);
      return;
    }

    if (ein === '') {
      alert(`Your EIN Tax ID Number cannot be empty!`);
      return;
    }

    if (ein.length !== 9) {
      alert(`Your EIN Tax ID Number must be 9 characters!`);
      return;
    }

    if (ownerName === '') {
      alert(`The owner's name cannot be empty!`);
      return;
    }

    if (streetName === '') {
      alert(`The street name cannot be empty!`);
      return;
    }

    if (city === '') {
      alert(`The city cannot be empty!`);
      return;
    }

    if (state === '') {
      alert(`The state cannot be empty!`);
      return;
    }

    if (zipCode === '') {
      alert(`The zip code cannot be empty!`);
      return;
    }

    let success = register(
      email,
      password,
      name,
      businessName,
      ownerName,
      streetName,
      city,
      state,
      zipCode
    );

    if (success) {
      /** user created | no errors */
      this.props.nextStep();
    } else {
      alert('Something went wrong');
      /** there was an error */
    }

    /** passed validation
     *
     * sign user up with firebase
     *
     * then pass data to firestore
     */

    // register()
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <div className="flex justify-center mt-8 space-x-3 text-center md:mx-32 lg:mx-96 md:mt-16 lg:mt-20">
        <div className="p-4 mx-auto bg-white border rounded-xl">
          <div className="items-center py-2">
            <div className="flex justify-center">
              <h2 className="text-3xl font-extrabold lg:text-4xl">Register</h2>
            </div>
            <h2>Business Info</h2>
          </div>

          <div className="py-4 space-y-4">
            {/* name */}
            <input
              className="w-full p-2 border rounded-lg focus:outline-primary"
              type="text"
              placeholder="Business Name"
              name="businessName"
              onChange={inputChange('businessName')}
              value={values.businessName}
            />

            {/* email */}
            <input
              className="w-full p-2 border rounded-lg focus:outline-primary"
              type="number"
              placeholder="EIN Tax ID Number"
              name="ein"
              onChange={inputChange('ein')}
              value={values.ein}
            />

            {/* owners name */}
            <input
              className="w-full p-2 border rounded-lg focus:outline-primary"
              type="text"
              placeholder="Owner's Name"
              name="ownerName"
              onChange={inputChange('ownerName')}
              value={values.ownerName}
            />

            <div className="flex space-x-3">
              {/* street name */}
              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="Street name"
                name="streetName"
                onChange={inputChange('streetName')}
                value={values.streetName}
              />

              {/* city */}
              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="City"
                name="city"
                onChange={inputChange('city')}
                value={values.city}
              />
            </div>

            <div className="flex space-x-3">
              {/* state */}
              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="State"
                name="state"
                onChange={inputChange('state')}
                value={values.state}
              />

              {/* zip code */}
              <input
                className="w-full p-2 border rounded-lg focus:outline-primary"
                type="text"
                placeholder="Zip code"
                name="zipCode"
                onChange={inputChange('zipCode')}
                value={values.zipCode}
              />
            </div>
          </div>

          {/* button */}

          <div>
            <div className="flex justify-between">
              <button
                className=" mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
                onClick={this.back}
              >
                Back
              </button>

              <button
                className=" mt-4 md:mt-5 lg:mt-6 px-8 font-semibold shadow-md py-1.5 md:text-lg text-white rounded-xl bg-gradient-to-r from-primary to-secondary transition duration-150 active:scale-90 transform"
                onClick={this.continue}
              >
                Sign Up
              </button>
            </div>

            <div className="flex items-center justify-center pt-3 space-x-1 md:space-x-2">
              <h2>Already have an account?</h2>
              <Link to="/login">
                <h2 className="text-lg font-semibold cursor-pointer text-primary">
                  Back to Login
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessInfoStep;
